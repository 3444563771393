import React from 'react';
import Container from 'react-bootstrap/Container';
import './LiveEmbed.css'

const LiveEmbed = ({embedLink}) => {
	return (
		<Container className='video-responsive mt-4 mb-4' >
        <iframe src={embedLink+'?modestbranding=1&rel=0&autoplay=1'}
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer"  
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation" 
        allowFullScreen></iframe>
        <div>Se la diretta non parte in automatico, fai click sul pulsante play (al centro dell'immagine) per riprodurre.</div>
		</Container>
	);
};

export default LiveEmbed;
